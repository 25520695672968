<template>
  <div style="text-align: justify">
    <p class="mb-3">
      The concentration of a solution of iron(II) sulfate,
      <chemical-latex content="FeSO4," />
      can be determined through a redox titration. A
      <latex-number number="50.00" unit="\text{mL}" />
      sample of the solution is diluted to
      <latex-number number="250.00" unit="\text{mL}" />
      with deionized water. A
      <latex-number number="25.00" unit="\text{mL}" />
      aliquot is then pipetted into an Erlenmeyer where it is acidified with sulfuric acid and then
      titrated with a standard solution of potassium dichromate,
      <chemical-latex content="K2Cr2O7\text{.}" />
      The reactants and products of the reaction (unbalanced) are as follows:
    </p>

    <p class="mb-6 pl-10">
      <chemical-latex content="Cr2O7^{2-}(aq) + Fe^{2+}(aq) -> Cr^{3+}(aq) + Fe^{3+}(aq)" />
    </p>

    <p class="mb-6">
      a) Write the balanced half-reactions (be sure to indicate which is the oxidation and which is
      the reduction half-reaction), as well as the overall balanced redox equation for the reaction
      between the dichromate ion and iron(II) ion in <b>acidic solution</b>.
    </p>

    <p class="mb-6">
      b) Using the data provided here (and above), calculate the molarity of the original iron
      sulfate solution. <b>Show your reasoning/calculations.</b>
    </p>

    <v-simple-table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Volume of original sample}$" />
          </td>
          <td>
            <latex-number number="50.00" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Volume of diluted solution}$" />
          </td>
          <td>
            <latex-number number="250.00" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Volume of diluted sample titrated}$" />
          </td>
          <td>
            <latex-number number="25.00" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Standard dichromate solution, }\ce{[Cr2O7^2-]}$" />
          </td>
          <td>
            <number-value :value="concDichromate" unit="\text{M}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Initial burette reading}$" />
          </td>
          <td>
            <number-value :value="initVol" unit="\text{mL}" />
          </td>
        </tr>
        <tr>
          <td style="vertical-align: middle">
            <stemble-latex content="$\textbf{Final burette reading}$" />
          </td>
          <td>
            <latex-number :number="finalVol" unit="\text{mL}" />
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '../displayers/ChemicalLatex';
import LatexNumber from '../displayers/LatexNumber';
import NumberValue from '../NumberValue';

export default {
  name: 'UPEIRedoxPreLab',
  components: {
    ChemicalLatex,
    StembleLatex,
    LatexNumber,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    concDichromate() {
      return this.taskState.getValueBySymbol('concDichromate').content;
    },
    concIronVal() {
      // Back-end value is scaled by a factor of 2.5
      return this.taskState.getValueBySymbol('concIronVal').content;
    },
    initVol() {
      return this.taskState.getValueBySymbol('initVol').content;
    },
    finalVol() {
      return (
        (((this.concIronVal.toFloat() / 2.5) * 0.025) / 5 / 6 / this.concDichromate.toFloat()) *
          1000 +
        this.initVol.toFloat()
      ).toFixed(2);
    },
  },
};
</script>
